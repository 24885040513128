import React from "react";
import './select.css';
import Paul from "../Animation/Paul";
import Alex from "../Animation/Alex";

function SelectPage() {
  return (
    <div className="select">

      <div className="select-right">
        <figure style={{ borderRadius: '25px' }}>
          <Paul/>
        </figure>
      </div>
      <div className="select-left">
      <figure style={{ borderRadius: '25px' }}>
        <Alex/>
      </figure>
      </div>
    </div>
  );
}

export default SelectPage;
