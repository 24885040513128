import React from "react";
import './banner.css'

function Banner() {
  return (
    <div className="banner">

      <div className="banner-left">
        <h2 style={{ paddingBottom: '5%' }}>Is it Flaw That Makes Us Human?</h2>
        <p>
          In todays's tech-driven world, AI is celebrated for solving problems and transforming industries, yet it falls short in addressing deeply human issues like mental illness and addiction.
          These silent battles, particularly additciton's  "silent countdown" to death, highlight the complexities of human suffering that technology has yet to touch.
        </p>
      </div>
    </div>
  );
}

export default Banner;
