// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/steelfish.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Countdown';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

.navbar-brand {
  font-size: 150%;
  font-family: 'Countdown', sans-serif;
}

a {
  font-size: 125%;
  font-family: 'Countdown', sans-serif;
}

  /* Custom styles */
  .navbar {
    background: linear-gradient(to right, #141e30, #243b55);
    padding: 15px 0;
    font-size: 20px;
  }

  .navbar-brand {
    padding: 0;
    font-size: 150%;
    font-family: 'Countdown', sans-serif;
  }

  .navbar-nav .nav-link {
    font-size: 125%;
    font-family: 'Countdown', sans-serif;
  }

  .clock {
    color: #fff;
    font-size: 24px;
  }

  .footer {
    color: white;
    background: #141414;
  }

  .card-title {
    font-size: 125%;
    font-family: 'Countdown', sans-serif;
  }

  body {
    background: black;
    color: white;
  }

  .card {
    background: #1e1e1e;
    border: none;
  }

  .card img {
    height: 200px;
    object-fit: cover;
  }

  @media (max-width: 991.98px) {
    .navbar-nav {
      text-align: center;
      width: 100%;
    }
    .navbar-brand {
      margin: 0 auto;
      text-align: center;
    }
    .navbar-toggler {
      position: absolute;
      right: 15px;
    }
  }
`, "",{"version":3,"sources":["webpack://./src/Components/Research/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,+DAAoD;AACtD;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;AAEA;EACE,eAAe;EACf,oCAAoC;AACtC;;EAEE,kBAAkB;EAClB;IACE,uDAAuD;IACvD,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,eAAe;IACf,oCAAoC;EACtC;;EAEA;IACE,eAAe;IACf,oCAAoC;EACtC;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,oCAAoC;EACtC;;EAEA;IACE,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE;MACE,kBAAkB;MAClB,WAAW;IACb;IACA;MACE,cAAc;MACd,kBAAkB;IACpB;IACA;MACE,kBAAkB;MAClB,WAAW;IACb;EACF","sourcesContent":["@font-face {\n  font-family: 'Countdown';\n  src: url('./fonts/steelfish.otf') format('opentype');\n}\n\n.navbar-brand {\n  font-size: 150%;\n  font-family: 'Countdown', sans-serif;\n}\n\na {\n  font-size: 125%;\n  font-family: 'Countdown', sans-serif;\n}\n\n  /* Custom styles */\n  .navbar {\n    background: linear-gradient(to right, #141e30, #243b55);\n    padding: 15px 0;\n    font-size: 20px;\n  }\n\n  .navbar-brand {\n    padding: 0;\n    font-size: 150%;\n    font-family: 'Countdown', sans-serif;\n  }\n\n  .navbar-nav .nav-link {\n    font-size: 125%;\n    font-family: 'Countdown', sans-serif;\n  }\n\n  .clock {\n    color: #fff;\n    font-size: 24px;\n  }\n\n  .footer {\n    color: white;\n    background: #141414;\n  }\n\n  .card-title {\n    font-size: 125%;\n    font-family: 'Countdown', sans-serif;\n  }\n\n  body {\n    background: black;\n    color: white;\n  }\n\n  .card {\n    background: #1e1e1e;\n    border: none;\n  }\n\n  .card img {\n    height: 200px;\n    object-fit: cover;\n  }\n\n  @media (max-width: 991.98px) {\n    .navbar-nav {\n      text-align: center;\n      width: 100%;\n    }\n    .navbar-brand {\n      margin: 0 auto;\n      text-align: center;\n    }\n    .navbar-toggler {\n      position: absolute;\n      right: 15px;\n    }\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
