import React from "react";
import './banner.css'

function BannerSecond() {
  return (
    <div className="banner">

      <div className="banner-left">
        <h2 style={{ paddingBottom: '5%' }}>Our Mission</h2>
        <p>Our mission is to explore how AI can model and address human issues such as mental health and addiction, through the synergy of art, technology, and science. We aim to develop innovative solutions that enhance well-being and promote research on the space.</p>
      </div>
    </div>
  );
}

export default BannerSecond;
