import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Adjust the path if your styles are in a different directory

const Projects = () => {
  return (


    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="art.png" className="card-img-top" alt="AI's Understanding of Addiction" />
              <div className="card-body">
                <h5 className="card-title">AI's Understanding of Addiction</h5>
                <a href="https://link1.com" className="stretched-link"></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="art.png" className="card-img-top" alt="AI's Understanding of Addiction" />
              <div className="card-body">
                <h5 className="card-title">AI's Understanding of Addiction</h5>
                <a href="https://link2.com" className="stretched-link"></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="art.png" className="card-img-top" alt="AI's Understanding of Addiction" />
              <div className="card-body">
                <h5 className="card-title">AI's Understanding of Addiction</h5>
                <a href="https://link3.com" className="stretched-link"></a>
              </div>
            </div>
          </div>
          {/* Add more cards as needed */}
        </div>
      </div>

    </div>
  );
};

export default Projects;
