// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/steelfish.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Countdown';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

.select {
  display: flex;
  height: 100vh;
  justify-content: center;
  gap: 0px;
  background: rgb(0, 0, 0);
  background: black;
  font-family: 'Countdown', sans-serif;
}
.select .select-right,
.select .select-left {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%; /* Adjusted width to bring them closer */
  padding: 0px;
  padding-top: 0%;
}
.select .select-right figure,
.select .select-left figure {
  width: 65%;
  height: 65%;
  background-color: black;
  padding-top: 0%;
}

@media only screen and (max-width: 1200px) {
  .select {
    height: 100% !important;
    flex-direction: column-reverse;
  }
  .select .select-right,
  .select .select-left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 85%;
    padding: 0px;
    padding-top: 10%;
    padding-bottom: 5%;
  }
  .select .select-right figure,
  .select .select-left figure {
    width: 75%;
    height: 75%;
    background-color: black;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SelectPage/select.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,+DAAoD;AACtD;;AAEA;EACE,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,QAAQ;EACR,wBAAwB;EACxB,iBAAiB;EACjB,oCAAoC;AACtC;AACA;;EAEE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,UAAU,EAAE,wCAAwC;EACpD,YAAY;EACZ,eAAe;AACjB;AACA;;EAEE,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE;IACE,uBAAuB;IACvB,8BAA8B;EAChC;EACA;;IAEE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;EACpB;EACA;;IAEE,UAAU;IACV,WAAW;IACX,uBAAuB;EACzB;AACF","sourcesContent":["@font-face {\n  font-family: 'Countdown';\n  src: url('./fonts/steelfish.otf') format('opentype');\n}\n\n.select {\n  display: flex;\n  height: 100vh;\n  justify-content: center;\n  gap: 0px;\n  background: rgb(0, 0, 0);\n  background: black;\n  font-family: 'Countdown', sans-serif;\n}\n.select .select-right,\n.select .select-left {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 45%; /* Adjusted width to bring them closer */\n  padding: 0px;\n  padding-top: 0%;\n}\n.select .select-right figure,\n.select .select-left figure {\n  width: 65%;\n  height: 65%;\n  background-color: black;\n  padding-top: 0%;\n}\n\n@media only screen and (max-width: 1200px) {\n  .select {\n    height: 100% !important;\n    flex-direction: column-reverse;\n  }\n  .select .select-right,\n  .select .select-left {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 85%;\n    padding: 0px;\n    padding-top: 10%;\n    padding-bottom: 5%;\n  }\n  .select .select-right figure,\n  .select .select-left figure {\n    width: 75%;\n    height: 75%;\n    background-color: black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
