import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Banner from './Components/Banner/Banner';
import BannerSecond from './Components/BannerSecond/BannerSecond';
import SelectPage from './Components/SelectPage/SelectPage';
import ParallaxScroll from './Components/Parallax/ParallaxScroll';
import Research from './Components/Research/Research'; // Import the new Research component
import Projects from './Components/Projects/Projects'; // Import the new Research component


function Home() {
  return (
    <div className="App">
      <ParallaxScroll>
        <div className="content-section">
          <Banner />
          <SelectPage />
          <BannerSecond />
        </div>
      </ParallaxScroll>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/research" element={<Research />} />
        <Route path="/projects" element={<Projects />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
