import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const Paul = () => {
  const canvasRef = useRef(null);
  let scene, camera, renderer;
  let nodes = [];
  let edges = [];

  useEffect(() => {
    const init = () => {
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x000000);

      camera = new THREE.PerspectiveCamera(70, canvasRef.current.clientWidth / canvasRef.current.clientHeight, 0.1, 100);
      camera.position.z = 30;

      renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);

      // Ellipsoid radii
      const a = 13; // Radius along the x-axis
      const b = 10.16; // Radius along the y-axis
      const c = 8.8; // Radius along the z-axis

      // Generate 60 nodes on the surface of the ellipsoid
      for (let i = 0; i < 150; i++) {
        let theta = Math.random() * 2 * Math.PI; // Random angle for longitude
        let phi = Math.acos(2 * Math.random() - 1); // Random angle for latitude

        // Cartesian coordinates for the ellipsoid surface
        let x = a * Math.sin(phi) * Math.cos(theta);
        let y = b * Math.sin(phi) * Math.sin(theta);
        let z = c * Math.cos(phi);

        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xbaffde }); // Yellow color
        let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(x, y, z);
        nodes.push(sphere);
        scene.add(sphere);
      }

      for (let i = 0; i < 100; i++) {
        let x, y, z;
        do {
          x = Math.random() * 2 - 1; // Generate a value between -1 and 1
          y = Math.random() * 2 - 1; // Generate a value between -1 and 1
          z = Math.random() * 2 - 1; // Generate a value between -1 and 1
        } while (x*x + y*y + z*z > 1); // Check if the point is inside the unit sphere

        // Scale the points to fit the ellipsoid
        x *= a;
        y *= b;
        z *= c;

        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(x, y, z);
        nodes.push(sphere);
        scene.add(sphere);
      }

      connectNodes();
      animate();
    };

    function connectNodes() {
      nodes.forEach((node, index) => {
        let connectedNodes = pickTwoRandomNodes(index, nodes.length);
        connectedNodes.forEach((nodeIndex) => {
          const otherNode = nodes[nodeIndex];
          createCylinderBetweenPoints(node.position, otherNode.position, 0.03, 0x00ffff, false); // Added visibility control
        });
      });
    }

    function createCylinderBetweenPoints(start, end, radius, color, visible) {
      const direction = new THREE.Vector3().subVectors(end, start);
      const orientation = new THREE.Matrix4();
      orientation.lookAt(start, end, new THREE.Object3D().up);
      orientation.multiply(new THREE.Matrix4().set(
        1, 0, 0, 0,
        0, 0, 1, 0,
        0, -1, 0, 0,
        0, 0, 0, 1
      ));
      const edgeGeometry = new THREE.CylinderGeometry(radius, radius, direction.length(), 8, 1);
      const edgeMaterial = new THREE.MeshBasicMaterial({ color: color });
      const edge = new THREE.Mesh(edgeGeometry, edgeMaterial);
      edge.applyMatrix4(orientation);
      edge.position.x = (start.x + end.x) / 2;
      edge.position.y = (start.y + end.y) / 2;
      edge.position.z = (start.z + end.z) / 2;
      edge.visible = visible;  // Control initial visibility
      scene.add(edge);
      edges.push({ line: edge, nodes: [start, end] }); // Storing the edge differently
    }

    function pickTwoRandomNodes(currentIndex, totalNodes) {
      let indices = new Set([currentIndex]);
      while (indices.size < 3) {
        indices.add(Math.floor(Math.random() * totalNodes));
      }
      indices.delete(currentIndex);
      return Array.from(indices);
    }

    function toggleEdgeVisibility(edgeIndex) {
      // Check if the edge index is within bounds
      if (edgeIndex < edges.length) {
        let edge = edges[edgeIndex].line;
        edge.visible = !edge.visible;  // Toggle visibility of the edge
      }
    }

    function randomlyToggleEdgeVisibility() {
      if (edges.length > 0) {
        const edgeIndex = Math.floor(Math.random() * edges.length);
        const edge = edges[edgeIndex].line;
        edge.visible = true;  // Turn the edge on

        // Set a timeout to turn the edge off after 250 milliseconds
        setTimeout(() => {
          edge.visible = false;
        }, 250);
      }
    }

    const handleResize = () => {
      camera.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    setInterval(randomlyToggleEdgeVisibility, 1000); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 750); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 600); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 650); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 1250); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 300); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 450); // Adjust interval as needed
    setInterval(randomlyToggleEdgeVisibility, 500); // Adjust interval as needed

    init();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const animate = () => {
    requestAnimationFrame(animate);
    scene.rotation.x += 0;
    scene.rotation.y += 0.0045;
    renderer.render(scene, camera);
  };

  return (
    <canvas ref={canvasRef} style={{borderRadius: '25px', width: '100%', height: '100%' }} />
  );
};

export default Paul;
