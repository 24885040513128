import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'; // Adjust the path if your styles are in a different directory

const Research = () => {
  return (


    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="https://cdn.vanderbilt.edu/vu-news/files/20230520091240/2022-Vanderbilt-University-wordmark.jpg" className="card-img-top" alt="Research 1" />
              <div className="card-body">
                <h5 className="card-title">Using AI to break the opioid addiction cycle</h5>
                <a href="https://www.news-medical.net/news/20240119/Using-AI-to-break-the-opioid-addiction-cycle.aspx" className="stretched-link"></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="https://home.dartmouth.edu/sites/home/files/styles/max_width_2880px/public/2024-02/ai-public-health.png?itok=M4hyrJKp" className="card-img-top" alt="Research 2" />
              <div className="card-body">
                <h5 className="card-title">AI Tools Reveal Knowledge Gaps in Addiction Treatment</h5>
                <a href="https://home.dartmouth.edu/news/2024/02/ai-tools-reveal-knowledge-gaps-addiction-treatment" className="stretched-link"></a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-4">
            <div className="card">
              <img src="https://nida.nih.gov/sites/default/files/styles/content_image_large/public/images/wordcloudnidaasks.jpg?itok=wFLyHTKE" className="card-img-top" alt="Research 3" />
              <div className="card-body">
                <h5 className="card-title">Can AI learn the language of addiction?</h5>
                <a href="https://nida.nih.gov/news-events/nida-asks/can-ai-learn-language-addiction" className="stretched-link"></a>
              </div>
            </div>
          </div>
          {/* Add more cards as needed */}
        </div>
      </div>

    </div>
  );
};

export default Research;
