import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

const ParallaxScroll = ({ children }) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const scrollRef = useRef(0);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const nodesRef = useRef([]);
  const edgesRef = useRef([]);

  useEffect(() => {
    const init = () => {
      const scene = new THREE.Scene();
      scene.background = new THREE.Color(0x000000);
      sceneRef.current = scene;

      const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 100; // Start further back for zoom-in effect
      cameraRef.current = camera;

      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
      rendererRef.current = renderer;

      // Ellipsoid radii
      const a = 13; // Radius along the x-axis
      const b = 10.16; // Radius along the y-axis
      const c = 8.8; // Radius along the z-axis

      // Generate nodes on the surface of the ellipsoid
      for (let i = 0; i < 150; i++) {
        let theta = Math.random() * 2 * Math.PI;
        let phi = Math.acos(2 * Math.random() - 1);

        let x = a * Math.sin(phi) * Math.cos(theta);
        let y = b * Math.sin(phi) * Math.sin(theta);
        let z = c * Math.cos(phi);

        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(x, y, z);
        nodesRef.current.push(sphere);
        scene.add(sphere);
      }

      for (let i = 0; i < 100; i++) {
        let x, y, z;
        do {
          x = Math.random() * 2 - 1;
          y = Math.random() * 2 - 1;
          z = Math.random() * 2 - 1;
        } while (x*x + y*y + z*z > 1);

        x *= a;
        y *= b;
        z *= c;

        const sphereGeometry = new THREE.SphereGeometry(0.1, 32, 32);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
        let sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
        sphere.position.set(x, y, z);
        nodesRef.current.push(sphere);
        scene.add(sphere);
      }

      connectNodes();
      animate();
    };

    const connectNodes = () => {
      nodesRef.current.forEach((node, index) => {
        let connectedNodes = pickTwoRandomNodes(index, nodesRef.current.length);
        connectedNodes.forEach((nodeIndex) => {
          const otherNode = nodesRef.current[nodeIndex];
          createCylinderBetweenPoints(node.position, otherNode.position, 0.03, 0x00ffff, false);
        });
      });
    };

    const createCylinderBetweenPoints = (start, end, radius, color, visible) => {
      const direction = new THREE.Vector3().subVectors(end, start);
      const orientation = new THREE.Matrix4();
      orientation.lookAt(start, end, new THREE.Object3D().up);
      orientation.multiply(new THREE.Matrix4().set(
        1, 0, 0, 0,
        0, 0, 1, 0,
        0, -1, 0, 0,
        0, 0, 0, 1
      ));
      const edgeGeometry = new THREE.CylinderGeometry(radius, radius, direction.length(), 8, 1);
      const edgeMaterial = new THREE.MeshBasicMaterial({ color: color });
      const edge = new THREE.Mesh(edgeGeometry, edgeMaterial);
      edge.applyMatrix4(orientation);
      edge.position.x = (start.x + end.x) / 2;
      edge.position.y = (start.y + end.y) / 2;
      edge.position.z = (start.z + end.z) / 2;
      edge.visible = visible;
      sceneRef.current.add(edge);
      edgesRef.current.push({ line: edge, nodes: [start, end] });
    };

    const pickTwoRandomNodes = (currentIndex, totalNodes) => {
      let indices = new Set([currentIndex]);
      while (indices.size < 3) {
        indices.add(Math.floor(Math.random() * totalNodes));
      }
      indices.delete(currentIndex);
      return Array.from(indices);
    };

    const toggleEdgeVisibility = (edgeIndex) => {
      if (edgeIndex < edgesRef.current.length) {
        let edge = edgesRef.current[edgeIndex].line;
        edge.visible = !edge.visible;
      }
    };

    const randomlyToggleEdgeVisibility = () => {
      if (edgesRef.current.length > 0) {
        const edgeIndex = Math.floor(Math.random() * edgesRef.current.length);
        const edge = edgesRef.current[edgeIndex].line;
        edge.visible = true;
        setTimeout(() => {
          edge.visible = false;
        }, 250);
      }
    };

    const handleResize = () => {
      if (cameraRef.current && rendererRef.current && containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        cameraRef.current.aspect = containerWidth / containerHeight;
        cameraRef.current.updateProjectionMatrix();
        rendererRef.current.setSize(containerWidth, containerHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    const animate = () => {
      requestAnimationFrame(animate);
      if (sceneRef.current && rendererRef.current && cameraRef.current) {
        sceneRef.current.rotation.x += 0;
        sceneRef.current.rotation.y += 0.0045;
        const scrollEffect = scrollRef.current * 0.3; // Increase multiplier for a longer animation
        cameraRef.current.position.z = 100 - scrollEffect; // Zoom in effect
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
    };

    const handleScroll = () => {
      if (containerRef.current) {
        scrollRef.current = containerRef.current.scrollTop;
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    } else {
      console.log('Container not found');
    }

    setInterval(randomlyToggleEdgeVisibility, 1000);
    setInterval(randomlyToggleEdgeVisibility, 750);
    setInterval(randomlyToggleEdgeVisibility, 600);
    setInterval(randomlyToggleEdgeVisibility, 650);
    setInterval(randomlyToggleEdgeVisibility, 1250);
    setInterval(randomlyToggleEdgeVisibility, 300);
    setInterval(randomlyToggleEdgeVisibility, 450);
    setInterval(randomlyToggleEdgeVisibility, 500);

    init();

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height: '100vh', width: '100%', overflowY: 'scroll', position: 'relative' }}>
      <canvas ref={canvasRef} style={{ display: 'block', width: '100%', height: '100%' }} />
      <div style={{ height: '300%', background: "black" }}>
        {children}
      </div>
    </div>
  );
};

export default ParallaxScroll;
